<template>
  <div class="card card-blog card-plain blog-horizontal mb-5">
    <div class="row">
      <div v-if="data.feature_image" class="col-lg-4">
        <div class="card-image shadow">
          <router-link :to="BlogPath">
            <img class="img rounded" :src="data.feature_image" />
          </router-link>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="card-body">
          <h3 class="card-title">
            <router-link :to="BlogPath">{{ data.title }}</router-link>
          </h3>
          <div class="card-description">
            <p>
              <span v-html="data.content"></span>...
              <router-link :to="BlogPath">Read More</router-link>
            </p>
          </div>
          <div class="author" style="margin : 0 !important;">
            <!-- <img
              src="img/faces/team-1.jpg"
              alt="..."
              class="avatar img-raised"
            /> -->
            <!-- <div class="text">
              <span class="name">Tom Hanks</span>
              <div class="meta">Drawn on 23 Jan</div>
            </div> -->
            <div>
              <span class="meta">{{ DateFromNow(data.created_at) }}</span>
              <span class="ml-2 mr-2 dot">·</span>
              <span v-if="data.read_time" class="meta"
                >{{ data.read_time }} read</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Moment from "moment";
export default {
  props: ["data"],
  methods: {
    DateFromNow(date) {
      // return Moment(date).format("MMM D YYYY");
      return Moment(date).fromNow();
    }
  },
  computed: {
    BlogPath() {
      return "/blog/" + this.data.id + "/" + this.data.slug;
    }
  }
};
</script>

<style>
.dot {
  font-size: 14px;
  color: rgba(117, 117, 117, 1);
  line-height: 20px;
  font-weight: 400;
}
</style>
